<script setup lang="ts">
import Button from "@/Components/Button.vue";
import Plus from "@/Components/Icons/Plus.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ref } from "vue";

const openIndex = ref(null);

const toggle = (index) => {
    openIndex.value = index;
};
</script>

<template>
    <div
        class="py-16 lg:pt-24 lg:pb-24 flex items-center bg-primary/5 bg-[url('@assets/images/home/sex-symbol.svg')] bg-no-repeat bg-left-bottom"
    >
        <div class="container">
            <div>
                <div
                    class="flex flex-col md:items-center gap-4 text-dark mt-4 lg:max-w-screen-md mx-auto lg:text-center"
                >
                    <h2 class="lg:text-5xl text-3xl font-bold leading-none">
                        Soyez à l’affût des nouveautés
                    </h2>

                    <div class="text-lg md:mt-4 font-medium">
                        Inscrivez-vous à la newsletter pour rester au courant
                        des nouveautés et bons plans pour votre prochaines
                        réservations
                    </div>
                </div>

                <div class="max-w-screen-md mx-auto flex md:mt-8 mt-4">
                    <input
                        type="email"
                        class="flex-1 rounded-l-md border py-5 h-full border-light focus:ring-0 focus:shadow-none focus:border-light"
                        placeholder="@ Ajouter votre adresse email"
                    />
                    <button
                        type="submit"
                        class="px-12 bg-dark text-white py-3 h-auto rounded-r-md"
                    >
                        S'inscrire
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
